import React, { useState } from "react";

import { motion } from "framer-motion";
import { fadeIn } from "../../utils/motion";

import { University } from "../../utils/icons/University";
import { Code } from "../../utils/icons/Code";
import { Languages } from "../../utils/icons/Languages";
import { Hackathons } from "../../utils/icons/Hackathons";

import "./AboutExperience.scss";

export const AboutExperience = () => {
  const [hatFlip, setHatFlip] = useState(false);

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={fadeIn("left", "spring", 2, 1)}
      viewport={{ once: true }}
      className="about-education"
    >
      <h2 className="section-header"> Education & Experience</h2>

      <div className="timeline-box">
        <h3 className="timeline-header">January 2021 - Present</h3>
        <p className="timeline-text">
          Working at a Cryptocurrency Exchange (React/TS & MUI5 & node/express)
        </p>
        <p className="timeline-text">UI Design & Implementation</p>
        <p className="timeline-text">
          Component Logic | Custom Hooks | Backend/API Integration
        </p>
      </div>

      <div className="timeline-box">
        <h3 className="timeline-header">September 2019 - Present</h3>
        <p className="timeline-text">
          Front-End & UI/UX Freelance <Code />
        </p>
      </div>

      <div className="timeline-box">
        <h3 className="timeline-header">December 2019 - October 2022</h3>
        <p className="timeline-text">
          Proffesional English engineering translations (now not so much,
          different grind)
        </p>
      </div>

      <div className="timeline-box">
        <h3 className="timeline-header university-header">
          October 2019 - February 2023
        </h3>
        <div
          className={`university-content ${hatFlip ? "flip" : "noflip"}`}
          onClick={() => setHatFlip(true)}
        >
          <p className="timeline-text university-text">
            Applied Computer Science student at Cracow University of Technology{" "}
          </p>
          {/* <i
            className={`fas fa-graduation-cap ${hatFlip ? "flip" : "noflip"}`}
            onClick={() => setHatFlip(true)}
          ></i> */}
          <University className={`fas fa-graduation-cap`} />
        </div>
      </div>

      <div className="skills">
        <div className="skill-box">
          <h3 className="skill-header">Languages</h3>
          <Languages />
          <p className="skill-text">English (daily use)</p>
          <p className="skill-text">Polish (slav squat)</p>
          <p className="skill-text">Spanish (un poco)</p>
        </div>

        <div className="skill-box">
          <h3 className="skill-header">Hackathons</h3>
          <Hackathons />
          <p className="skill-text">U HACK</p>
          <p className="skill-text">HackeRAM</p>
        </div>
      </div>
    </motion.div>
  );
};
