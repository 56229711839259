export const validate = ({ name, mail, message }) => {
  let errors = [];
  let errorFields = [];

  if (name === "" || name.length < 1 || name.length > 100) {
    errors.push(`NAME length expected to be (1-100)`);
    errorFields.push("name");
  }

  if (mail === "" || mail.length < 5 || mail.length > 50) {
    errors.push(`E-MAIL length expected to be (5-50)`);
    errorFields.push("mail");
  }

  if (!mail.includes("@")) {
    errors.push("E-MAIL field without @");
    errorFields.push("mail");
  }

  if (message === "" || message.length < 1 || message.length > 10000) {
    errors.push(`MESSAGE length expected to be over (1-10K)`);
    errorFields.push("message");
  }

  return { errors, errorFields };
};
