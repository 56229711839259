import React from "react";

import { motion } from "framer-motion";

import "./ProjectMenu.scss";

const technologyFilterList = [
  "ALL",
  "React",
  "Typescript",
  "API",
  "SCSS",
  "TailwindCSS",
];
const categoryFilterList = ["ALL", "Blockchain", "Game", "UI/UX"];

export const ProjectMenu = ({
  technologyFilter,
  setTechnologyFilter,
  categoryFilter,
  setCategoryFilter,
}) => {
  const changeTechnologyFilter = (newFilter) => {
    setTechnologyFilter(newFilter.target.innerText);
  };

  const changeCategoryFilter = (newFilter) => {
    setCategoryFilter(newFilter.target.innerText);
  };

  return (
    <ul className="project-menu">
      <motion.section
        initial={{
          opacity: 0,
          translateY: -200,
        }}
        animate={{ opacity: 1, translateX: 0, translateY: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
        viewport={{ once: true }}
        className="section-menu"
      >
        <span className="menu-title">Technology</span>
        <div className="list">
          {technologyFilterList.map((filter, index) => {
            const filterState =
              filter === technologyFilter ? "active" : "inactive";
            return (
              <motion.li
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.075 },
                }}
                whileTap={{ scale: 0.9 }}
                className={`menu-item ${filterState}`}
                onClick={changeTechnologyFilter}
                key={filter}
              >
                {filter}
              </motion.li>
            );
          })}
        </div>
      </motion.section>
      <motion.section
        initial={{
          opacity: 0,
          translateY: -200,
        }}
        animate={{ opacity: 1, translateX: 0, translateY: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
        viewport={{ once: true }}
        className="section-menu"
      >
        <span className="menu-title">Category</span>
        <div className="list category-list">
          {categoryFilterList.map((filter, index) => {
            const filterState =
              filter === categoryFilter ? "active" : "inactive";
            return (
              <motion.li
                whileHover={{
                  scale: 1.1,
                  transition: { duration: 0.075 },
                }}
                whileTap={{ scale: 0.9 }}
                className={`menu-item ${filterState}`}
                onClick={changeCategoryFilter}
                key={filter}
              >
                {filter}
              </motion.li>
            );
          })}
        </div>
      </motion.section>
    </ul>
  );
};
