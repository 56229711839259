import React, { useEffect } from "react";

import { motion } from "framer-motion";
import { dropIn } from "../../../utils/motion";

import "./ResetAnimation.scss";

export const ResetAnimation = ({ time, resetFilters }) => {
  useEffect(() => {
    const resetFilterTimeout = setTimeout(() => {
      resetFilters();
    }, time);

    return () => clearTimeout(resetFilterTimeout);
  }, []);

  const reset = () => {
    resetFilters();
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={dropIn}
      className="reset-filters"
    >
      <div className="info-container ">
        <span className="main-info">Sorry, not yet :)</span>
        <span className="sub-info">Reseting in {time / 1000} seconds</span>
        <div className="btn-container">
          <button className="reset-btn" onClick={reset}>
            LOL? DO IT NOW!
          </button>
        </div>
      </div>
    </motion.div>
  );
};
