import React from "react";
import { Project } from "../../featured-work/Project";

import "./ProjectList.scss";

const ProjectList = ({ projects }) => {
  return (
    <>
      {projects.map((data) => {
        return <Project data={data} key={data.index} />;
      })}
    </>
  );
};

export default ProjectList;
