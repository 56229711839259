import React from "react";
import { projectData } from "./featuredWorkData";
import { Project } from "./Project.jsx";

import { slideIn } from "../../utils/motion";
import { motion } from "framer-motion";

import "./FeaturedWork.scss";

export const FeaturedWork = () => {
  return (
    <section className="fw-wrapper">
      <div className="fw">
        <motion.div
          initial="hidden"
          variants={slideIn("left", "tween", 0.25, 0.5)}
          whileInView="show"
          viewport={{ once: true }}
          className="fw-header"
        >
          <h2 className="section-title">Featured projects</h2>
        </motion.div>
        <div className="fw-content">
          {projectData.map((fw, index) => {
            if (fw.featured) return <Project data={fw} key={index} />;
            return null;
          })}
        </div>
      </div>
    </section>
  );
};
