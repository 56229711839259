import React from "react";

import { motion } from "framer-motion";
import { contactAlertVariant } from "../../../utils/motion";

import { Close } from "../../../utils/icons/Close";

import "./ContactAlert.scss";

export const RenderAlert = ({ status, setStatus, errors }) => {
  // if (!errors) return;

  const resetStatus = () => {
    console.log("Reseting status");
    setStatus("");
  };

  if (status === "success") {
    errors = [];
  }

  return (
    <motion.div
      variants={contactAlertVariant()}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={`alert-box ${status}`}
    >
      <div className="alert-info">
        <span className={`alert-text ${status}`}>{status}</span>
        <div className="close-box" onClick={resetStatus}>
          <Close />
        </div>
      </div>
      <div className="error-messages">
        {errors.map((errorMsg, index) => {
          return (
            <span className="error-msg" key={index}>
              {errorMsg}
            </span>
          );
        })}
      </div>
    </motion.div>
  );
};
