import React from "react";

export const Location = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 512 444.66"
    >
      <path d="m197.01 174.98 55.88-55.9c2.95-2.94 3.32-3.07 6.39 0l55.71 55.9V231h-41.41v-37.83c0-2.56-2.1-4.65-4.66-4.65h-25.84c-2.55 0-4.65 2.09-4.65 4.65V231h-41.42v-56.02zm80.68 208.01a31.082 31.082 0 0 1-17.82 5.72c-5.76.01-11.54-1.55-16.63-4.78l-.08-.09c-15.89-10.11-30.56-21.25-43.86-33.11-13.77-12.2-26.17-25.35-37.07-39.07-14.75-18.49-26.83-38.07-36.07-57.99-9.43-20.39-15.92-41.33-19.24-62.03-3.47-21.51-3.53-42.6-.01-62.49 3.54-19.95 10.62-38.79 21.41-55.76 4.34-6.74 9.35-13.34 15.03-19.63 5.39-6.09 11.48-11.83 18.15-17.13 15.02-11.96 31.22-21.16 47.96-27.33 16.96-6.26 34.62-9.42 52.32-9.3 17.63.13 35.04 3.5 51.62 10.25 16.04 6.65 31.33 16.23 45.27 28.95 4.91 4.45 9.6 9.43 13.99 14.79 4.47 5.43 8.43 11.04 11.91 16.75 11.48 18.99 18.55 40.3 21.5 62.78 3.01 22.78 1.83 46.88-3.15 70.99-7.51 36.48-23.21 71.7-44.95 102.49-21.4 30.31-48.86 56.64-80.28 75.99zm-13.54-22.14c-2.46 1.8-5.8 2.04-8.53.29-29.52-18.79-54.35-41.36-73.82-65.85-26.89-33.74-43.84-71.15-49.63-107.18-5.9-36.53-.39-71.66 17.79-100.24 7.17-11.3 16.32-21.59 27.48-30.46 25.66-20.46 54.97-31.24 84.13-31.03 28.12.2 55.86 10.7 79.83 32.57 8.43 7.65 15.52 16.43 21.31 25.95 19.55 32.19 23.76 73.25 15.15 114.87-13.4 65.2-56.46 126.33-113.71 161.08zm208.57 55.65-36.95-108.46c-1.61-4.74-4.32-9.19-9.18-9.19h-20.68l13.86-28.15h6.82c11.58 0 20.32 4.92 26.92 12.49 4.29 4.91 7.05 10.47 8.88 15.83L512 444.66H0l44.38-144.87c2.03-6.49 5.46-13.25 10.69-18.48h.11c6.47-6.44 14.74-10.61 25.22-10.61h13.74l15.09 28.15H80.4c-4.87 0-7.77 4.57-9.18 9.19L37.98 416.5h434.74zM254.25 74.96l-83.02 77.95 20.48 10.76 58.64-59.14c5.01-5.05 5.07-5.16 10.06-.22l59.89 59.36 20.48-10.76-83.15-78.07c-1.5-1.4-1.71-1.56-3.38.12z" />
    </svg>
  );
};

// export const Location = () => {
//   return (
//     <svg
//       fill="none"
//       stroke="currentColor"
//       stroke-width="0.75"
//       viewBox="0 0 24 24"
//       xmlns="http://www.w3.org/2000/svg"
//       ariaHidden="true"
//     >
//       <g strokeWidth="0"></g>
//       <g strokeLinecap="round" strokeLinejoin="round"></g>
//       <g id="SVGRepo_iconCarrier">
//         {" "}
//         <path
//           d="M9.14844 7.48828C8.58844 7.48828 8.14844 7.93828 8.14844 8.48828C8.14844 9.03828 8.59844 9.48828 9.14844 9.48828C9.69844 9.48828 10.1484 9.03828 10.1484 8.48828C10.1484 7.93828 9.69844 7.48828 9.14844 7.48828Z"
//           fill="#292D32"
//         ></path>{" "}
//         <path
//           d="M21.46 5.04C20.62 3.09 18.77 2 16.19 2H7.81C4.6 2 2 4.6 2 7.81V16.19C2 18.77 3.09 20.62 5.04 21.46C5.23 21.54 5.45 21.49 5.59 21.35L21.35 5.59C21.5 5.44 21.55 5.22 21.46 5.04ZM10.53 12.24C10.14 12.62 9.63 12.8 9.12 12.8C8.61 12.8 8.1 12.61 7.71 12.24C6.69 11.28 5.57 9.75 6 7.93C6.38 6.28 7.84 5.54 9.12 5.54C10.4 5.54 11.86 6.28 12.24 7.94C12.66 9.75 11.54 11.28 10.53 12.24Z"
//           fill="#292D32"
//         ></path>{" "}
//         <path
//           d="M19.4689 20.5295C19.6889 20.7495 19.6589 21.1095 19.3889 21.2595C18.5089 21.7495 17.4389 21.9995 16.1889 21.9995H7.80892C7.51892 21.9995 7.39892 21.6595 7.59892 21.4595L13.6389 15.4195C13.8389 15.2195 14.1489 15.2195 14.3489 15.4195L19.4689 20.5295Z"
//           fill="#292D32"
//         ></path>{" "}
//         <path
//           d="M22.0017 7.80892V16.1889C22.0017 17.4389 21.7517 18.5189 21.2617 19.3889C21.1117 19.6589 20.7517 19.6789 20.5317 19.4689L15.4117 14.3489C15.2117 14.1489 15.2117 13.8389 15.4117 13.6389L21.4517 7.59892C21.6617 7.39892 22.0017 7.51892 22.0017 7.80892Z"
//           fill="#292D32"
//         ></path>{" "}
//       </g>
//     </svg>
//   );
// };
