import React, { useState, useEffect } from "react";
import "./Projects.scss";

import { ProjectMenu } from "./components/ProjectMenu.jsx";
import { ResetAnimation } from "./components/ResetAnimation";

import ProjectList from "./components/ProjectList";

import { projectData } from "../featured-work/featuredWorkData";

export const Projects = () => {
  const [filteredProjects, setFilteredProjects] = useState(projectData);
  const [technologyFilter, setTechnologyFilter] = useState("ALL");
  const [categoryFilter, setCategoryFilter] = useState("ALL");

  /* FILTER BOTH */
  useEffect(() => {
    let result = projectData;
    result = filterTechnology(result);
    result = filterCategory(result);

    setFilteredProjects(result);
  }, [technologyFilter, categoryFilter]);

  const filterTechnology = (result) => {
    if (technologyFilter === "ALL") return result;
    return result.filter((project) =>
      project.technologies.includes(technologyFilter)
    );
  };

  const filterCategory = (result) => {
    if (categoryFilter === "ALL") return result;
    return result.filter((project) => {
      if (project.category.toLowerCase() === categoryFilter.toLowerCase()) {
        return project;
      }
    });
  };

  const resetFilters = () => {
    console.log("TIME TO RESET FILTER MF");
    setTechnologyFilter("ALL");
    setCategoryFilter("ALL");
  };

  return (
    <div className="projects-wrapper">
      <div className="projects">
        <ProjectMenu
          technologyFilter={technologyFilter}
          setTechnologyFilter={setTechnologyFilter}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
        />

        {filteredProjects.length > 0 ? (
          <ProjectList projects={filteredProjects} />
        ) : (
          <ResetAnimation resetFilters={resetFilters} time={5000} />
        )}
      </div>
    </div>
  );
};
