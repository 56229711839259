import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { navVariants, navMenuVariants } from "../../utils/motion";
import { motion } from "framer-motion";

/* icons */
import { Github } from "../../utils/icons/Github";
import { Linkedin } from "../../utils/icons/Linkedin";

import Logo from "./components/Logo";
import MobileBurger from "./components/MobileBurger";

import "./Navigation.scss";

export const Navigation = () => {
  const [menu, setMenu] = useState("mobile-inactive");

  function toggleMenu() {
    menu === "mobile-active"
      ? setMenu("mobile-inactive")
      : setMenu("mobile-active");
  }

  return (
    <motion.nav
      initial="hidden"
      animate="visible"
      variants={navVariants}
      className={`nav-wrapper ${menu}`}
    >
      <div className="nav">
        <div className="mobile-wrapper">
          <Logo />
          <MobileBurger
            menuActive={menu === "mobile-active"}
            handleClick={toggleMenu}
          />
        </div>
        <motion.ul
          initial="hidden"
          animate={menu === "mobile-active" ? "mobileOpen" : "mobileClosed"}
          variants={navMenuVariants}
          className={`nav-menu`}
        >
          <NavLink to="/" end onClick={() => setMenu("mobile-inactive")}>
            <li className="nav-item">Home</li>
          </NavLink>
          <NavLink to="projects" onClick={() => setMenu("mobile-inactive")}>
            <li className="nav-item">My work</li>
          </NavLink>
          <NavLink to="about" onClick={() => setMenu("mobile-inactive")}>
            <li className="nav-item">About</li>
          </NavLink>
          <NavLink to="contact" onClick={() => setMenu("mobile-inactive")}>
            <li className="nav-item">Contact</li>
          </NavLink>
          <div className="nav-icons">
            <a
              href="https://github.com/kossin1337"
              target="_blank"
              rel="noreferrer"
              className="github"
            >
              <Github />
            </a>
            <a
              href="https://www.linkedin.com/in/kossin1337/"
              target="_blank"
              rel="noreferrer"
              className="linkedin"
            >
              <Linkedin />
            </a>
          </div>
        </motion.ul>
      </div>
    </motion.nav>
  );
};
