import React from "react";

import { Github } from "../../utils/icons/Github";
import { Linkedin } from "../../utils/icons/Linkedin";

import { footerVariants } from "../../utils/motion";
import { motion } from "framer-motion";

import "./Footer.scss";

export const Footer = () => {
  return (
    <motion.footer
      initial="hidden"
      whileInView="show"
      variants={footerVariants}
      className="footer-wrapper"
    >
      <div className="footer">
        <div className="footer-item by-kossin">
          <p>By Piotr Swobodzinski</p>
        </div>
        <div className="footer-item copyright">
          <p>{`Copyright © ${new Date().getFullYear()} Kossin`}</p>
        </div>
        <div className="footer-icons footer-item">
          <a
            href="https://github.com/kossin1337"
            target="_blank"
            rel="noreferrer"
            className="github"
          >
            <Github />
          </a>
          <a
            href="https://www.linkedin.com/in/kossin1337/"
            target="_blank"
            rel="noreferrer"
            className="linkedin"
          >
            <Linkedin />
          </a>
        </div>
      </div>
    </motion.footer>
  );
};
