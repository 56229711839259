import React from "react";

import { motion } from "framer-motion";
import { textVariant } from "../../utils/motion";

import ContactInfo from "./components/ContactInfo";
import ContactForm from "./components/ContactForm";

import "./Contact.scss";

export const Contact = () => {
  return (
    <div className="contact-wrapper">
      <div className="contact">
        <div className="contact-header">
          <motion.h3
            initial="hidden"
            whileInView="show"
            variants={textVariant(0.2)}
            className="sub-title"
          >
            Let's get in touch
          </motion.h3>
          <motion.h2
            initial="hidden"
            whileInView="show"
            variants={textVariant(0.4)}
            className="title"
          >
            Contact Me
          </motion.h2>
        </div>
        <motion.div
          initial={{ scale: 0.2, opacity: 0, translateY: "100%" }}
          animate={{ scale: 1, opacity: 1, translateY: 0 }}
          transition={{ duration: 0.75, delay: 0.75 }}
          className="contact-content"
        >
          <ContactInfo />
          <ContactForm />
        </motion.div>
      </div>
    </div>
  );
};
