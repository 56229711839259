import React from "react";
import { Link } from "react-router-dom";

import profilePicture from "./images/profilePicture.jpg";
import CV from "./resume/resume.pdf";

import { motion } from "framer-motion";
import { gifVariant, slideIn, textVariant } from "../../utils/motion";

import { Download } from "../../utils/icons/Download";

import "./AboutIntroduction.scss";

export const AboutIntroduction = () => {
  return (
    <div className="introduction">
      <div className="info-container">
        <motion.h1
          initial="hidden"
          whileInView="show"
          variants={textVariant(0.5)}
          viewport={{ once: true }}
          className="title special-text"
        >
          Welcome G!
        </motion.h1>
        <motion.h2
          initial="hidden"
          whileInView="show"
          variants={textVariant(1)}
          viewport={{ once: true }}
          className="sub-title special-text"
        >
          I'm Piotr Swobodziński | Kossin
        </motion.h2>
        <motion.div
          initial="hidden"
          whileInView="show"
          variants={slideIn("left", "tween", 1.5, 0.75)}
          viewport={{ once: true }}
          className="text"
        >
          <div className="header-container">
            <span className="header-shadow header">TECH</span>
            <span className="header-text header">TECH</span>
          </div>
          <p className="important">
            My journey with web development started in{" "}
            <span className="special-text">{` <2017 /> `}</span>
          </p>
          <p className="important">
            Recently I'm working on side projects & exploring technologies like
            (Next13, Solidity, web3.js). I think the key is to keep your brain
            ocupied with entertaining shit so u enjoy the process
          </p>
          <div className="header-container">
            <span className="header-shadow header">PERSONAL</span>
            <span className="header-text header">PERSONAL</span>
          </div>
          <p className="important">
            I've always paid atention to detail, therefore i really enjoy UI/UX.
            Friends describe me as open-minded and enthusiastic. Besides web-dev
            I'm interested in Martial Arts, Cryptocurrency, skateboarding and
            music.
          </p>
          <p className="less-important">
            I'm open to <Link to="/contact">collaborate</Link> on Hackathons
          </p>
          <Link to={CV} target="_blank" download>
            <button className="resume-btn">
              Resume
              <Download />
            </button>
          </Link>
        </motion.div>
      </div>

      <motion.div
        initial="hidden"
        animate="show"
        variants={gifVariant(2)}
        viewport={{ once: true }}
        className="profile-pic"
      >
        <figure className="figure">
          <img src={profilePicture} alt="ProfileImage" />
          <div className="fig">
            <figcaption>Don't count the days,</figcaption>
            <figcaption>make the days count</figcaption>
            <figcaption>~ Muhammad Ali</figcaption>
          </div>
        </figure>
      </motion.div>
    </div>
  );
};

// "Happiness is not something ready-made. It comes from your own actions." - Dalai Lama
// "Don't count the days, make the days count." - Muhammad Ali
