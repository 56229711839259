import React from "react";

/* icons */
import { EMail } from "../../../utils/icons/EMail";
import { Location } from "../../../utils/icons/Location";
import { Phone } from "../../../utils/icons/Phone";

import "./ContactInfo.scss";

const ContactInfo = () => {
  return (
    <div className="contact-info">
      <span className="info-heading">Click to copy</span>
      <div className="info-container">
        <div
          className="info-card"
          onClick={() => {
            navigator.clipboard.writeText("piotrswobodzinski@gmail.com");
          }}
        >
          <EMail />
          <span className="info-text">piotrswobodzinski@gmail.com</span>
        </div>
        <div
          className="info-card"
          onClick={() => {
            navigator.clipboard.writeText("+48 666 506 932");
          }}
        >
          <Phone />
          <span className="info-text">+48 666 506 932</span>
        </div>
        <div
          className="info-card"
          onClick={() => {
            navigator.clipboard.writeText("Cracow, Poland");
          }}
        >
          <Location />
          <span className="info-text">Cracow, Poland</span>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
