import React from "react";
import { Link } from "react-router-dom";

import {
  staggerContainer,
  mainHeroTextVariant,
  mainSubTextVariant,
  mainLinks,
  gifVariant,
} from "../../utils/motion";
import { motion } from "framer-motion";

/* gifs import */
import reactGif from "./gifs/react.gif";
import mainGif from "./gifs/main.gif";
import githubGif from "./gifs/github.gif";

/* icons */
import { Github } from "../../utils/icons/Github";
import { Linkedin } from "../../utils/icons/Linkedin";

/* rerender hook */
import { useIntro } from "../../hooks/useIntro";

import "./Main.scss";

export const Main = () => {
  const timePassed = useIntro();
  console.log("Time Passed: ", timePassed);

  return (
    <main className="main">
      <motion.section
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className="hero-section"
      >
        <motion.h1
          variants={mainHeroTextVariant(0.3)}
          animate={timePassed}
          viewport={{ once: true }}
          className="hero-title hero-text"
        >
          Piotr Swobodzinski | Kossin
        </motion.h1>
        <motion.h2
          variants={mainSubTextVariant(0.4)}
          viewport={{ once: true }}
          className="hero-sub-title hero-text"
        >
          WEB Developer | UI/UX Design
        </motion.h2>
        <motion.div
          variants={mainLinks(1)}
          viewport={{ once: true }}
          className="hero-links"
        >
          <Link to="/projects">
            <button className="hero-btn">See my projects</button>
          </Link>
          <div className="main-icons">
            <a
              href="https://github.com/kossin1337"
              target="_blank"
              rel="noreferrer"
              className="github social-icon"
            >
              <Github />
            </a>
            <a
              href="https://www.linkedin.com/in/kossin1337/"
              target="_blank"
              rel="noreferrer"
              className="linkedin social-icon"
            >
              <Linkedin />
            </a>
          </div>
        </motion.div>
      </motion.section>

      <motion.div
        initial="hidden"
        animate="show"
        variants={gifVariant(1)}
        className="gif-wrapper"
      >
        <img className="main-gif gif" src={mainGif} alt="Main gif animation" />
      </motion.div>
      <motion.img
        initial="hidden"
        animate="show"
        variants={gifVariant(1)}
        className="react-gif gif"
        src={reactGif}
        alt="React Logo animation"
      />
      <motion.img
        initial="hidden"
        animate="show"
        variants={gifVariant(1)}
        className="github-gif gif"
        src={githubGif}
        alt="Github Animation"
      />
    </main>
  );
};
