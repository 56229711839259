import React from "react";
import { IMAGES } from "./index.js";

import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../../utils/motion.js";

import "./AboutSkills.scss";

export const AboutSkills = () => {
  return (
    <div className="about-skills">
      <motion.h2
        initial="hidden"
        whileInView="show"
        variants={textVariant}
        viewport={{ once: true }}
        className="skills-title"
      >
        Skills & Technologies
      </motion.h2>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        className="images"
      >
        {Object.values(IMAGES).map((value, index) => {
          return (
            <motion.img
              initial={{
                opacity: 0,
                translateX: index % 3 === 0 ? -50 : 50,
                translateY: index % 2 === 0 ? -50 : 50,
              }}
              animate={{ opacity: 1, translateX: 0, translateY: 0 }}
              transition={{ duration: 0.5, delay: 1 + index * 0.1 }}
              src={value}
              key={index}
              className="technology-img"
              alt="technology"
            />
          );
        })}
      </motion.div>
    </div>
  );
};
