import React, { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";

import { validate } from "../formValidation";
import { RenderAlert } from "./ContactAlert.jsx";

import "./ContactForm.scss";

const ContactForm = () => {
  const [status, setStatus] = useState("");
  const [errors, setErrors] = useState(null);

  const form = useRef();
  const nameRef = useRef(null);
  const mailRef = useRef(null);
  const messageRef = useRef(null);

  useEffect(() => {
    setTimeout(
      () => {
        setStatus("");
      },
      status === "success" ? 2500 : 5000
    );
  }, [status]);

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const template_params = {
      name: nameRef.current.value,
      mail: mailRef.current.value,
      message: messageRef.current.value,
    };

    const { errors, errorFields } = validate(template_params);

    if (errorFields) {
      if (errorFields.includes("name")) {
        console.log("FUCK YOU");
      }
    }

    /* Target first input with error */
    if (errorFields.includes("name")) {
      nameRef.current.focus();
    } else if (errorFields.includes("mail")) {
      mailRef.current.focus();
    } else if (errorFields.includes("message")) {
      messageRef.current.focus();
    }

    if (errors.length === 0) {
      emailjs
        .send(
          "service_zc579y4",
          "Template_7DEU9LLAR4XXG",
          template_params,
          "Qz-B7GpGRcdTU9C9L"
        )
        .then(
          (response) => {
            setStatus("success");

            console.log(response.text);
            nameRef.current.value = "";
            mailRef.current.value = "";
            messageRef.current.value = "";
          },
          (error) => {
            console.log(error.text);
            setStatus("fail");
          }
        );
    } else {
      setErrors(errors);
      setStatus("fail");
    }
  };

  return (
    <form className="contact-form" ref={form}>
      <div className="input-container" key="contact-name">
        <label htmlFor="">Full Name</label>
        <input type="text" name="name" ref={nameRef} required />
      </div>
      <div className="input-container" key="contact-mail">
        <label htmlFor="">E-mail</label>
        <input type="email" name="mail" ref={mailRef} required />
      </div>
      <div className="input-container" key="contact-message">
        <label htmlFor="">Message</label>
        <textarea type="text" name="message" ref={messageRef} required />
      </div>
      <div className="submit-box">
        <button className="submit-btn" type="submit" onClick={handleSubmit}>
          ship it
        </button>
        {status ? (
          <RenderAlert status={status} setStatus={setStatus} errors={errors} />
        ) : (
          <></>
        )}
      </div>
    </form>
  );
};

export default ContactForm;
