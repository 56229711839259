import CoinREX from "./images/coinREX.png";
import ColorPicker from "./images/ColorPicker.png";
import Leet from "./images/leet.png";
import NFS from "./images/NFS.png";

export const projectData = [
  {
    index: 1,
    featured: true,
    projectName: "CoinREX - Crypto App",
    category: "project",
    description: `Cryptocurrency price tracker with real-time market data, using various API's. 
    Containing sort/filter/search functionalities, crypto charts and login/register using Auth0, routing using react-router, cryptocurrency charts using Chart.js, Redux Toolkit for better state management and API calls. 
    CoinRANKING for Cryptocurrency Price Charts based on Time Period, Social & Project links, Articles & Info, & Global Statistics. CoinGecko for exchanges data, as well as News API to provide different cryptocurrency related news`,
    technologies: [
      "React",
      "API",
      "Javascript",
      "React-Router",
      "SCSS",
      "Redux Toolkit",
      "Chart.js",
    ],
    liveDemo: "https://coinrex.netlify.app/",
    github: "https://github.com/Kossin1337/crypto-app",
    image: CoinREX,
  },
  {
    index: 2,
    featured: true,
    category: "game",
    projectName: "#LEET a.k.a. WORDLE CLONE",
    description:
      "Where did the idea came from? I just liked wordle and tried to reproduce it myself with React & TS. Game logic is made with the help a of custom hook. Typescript definatelly improved readability code readability. SCSS for custom styles (just a preference)",
    technologies: ["React", "Typescript", "SCSS"],
    liveDemo: "https://itlit.netlify.app",
    github: "https://github.com/Kossin1337/wordle-game",
    image: Leet,
  },
  {
    index: 3,
    featured: true,
    category: "design",
    projectName: "NEED FOR SPEED GARAGE (BGR)",
    description: `Custom website for my close friend's business. As a kid i used to play a lot of NFS Underground 2. I decided to implment the design with a font from this masterpiece. Animations made with framer-motion (same as in portfolio here), but the carousell is made with react-spring. I wanted to test both here, and formulate my own opinion.`,
    technologies: ["React", "Javascript", "Framer-Motion", "SCSS"],
    liveDemo: "https://coinrex.netlify.app/",
    github: "https://github.com/Kossin1337/nfs-theme-bgr",
    image: NFS,
  },
  {
    index: 4,
    featured: false,
    category: "project",
    projectName: "Color Picker & Palette Generator",
    description:
      "This website is a small encyclopedia about colors. Allowing you to generate a custom color or palette. Website uses React Context API and allows you to save your favorite colors/palettes. You can explore and learn about color models by reading blog articles",
    technologies: ["React", "Javascript", "React-Router", "SCSS"],
    liveDemo: "https://cooloor.netlify.app/",
    github: "https://github.com/Kossin1337/Color-picker-palette-generator",
    image: ColorPicker,
  },
];

export const technologiesColors = {
  React: "rgba(97, 219, 251, 1)", // #61DBFB
  "React-Router": "rgba(114, 236, 252, 1)", // #72ECFC
  Javascript: "rgba(240, 219, 79, 1)", // #F0DB4F
  Typescript: "rgba(0, 122, 204,1)",
  SCSS: "rgba(204, 102, 153, 1)", // #CC6699
  Redux: "rgba(118, 74, 188, 1)", // #764abc
  "Redux Toolkit": "rgba(118, 74, 188, 1)", // #764abc
  TailwindCSS: "#fff512",
  "Chart.js": "rgba(247, 115, 119, 1)", // #f77377
  "Framer-Motion": "rgba(97, 219, 251, 1)",
  API: "rgba(144, 238, 144, 1)",
};
