import React from "react";
import "./Project.scss";

import {
  staggerContainer,
  projectButtonVariant,
  gifVariant,
  textVariant,
} from "../../utils/motion";
import { motion } from "framer-motion";

import { technologiesColors } from "./featuredWorkData";

export const Project = ({ data }) => {
  const {
    index,
    projectName,
    technologies,
    description,
    image,
    github,
    liveDemo,
  } = data;

  return (
    <div className="project" key={index}>
      <motion.div
        initial="hidden"
        whileInView="show"
        variants={staggerContainer(0.2)}
        viewport={{ once: true }}
        className="info"
      >
        <motion.h2 variants={textVariant(0.5)} className="title">
          {projectName}
        </motion.h2>
        <motion.p variants={textVariant(0.7)} className="description">
          {description}
        </motion.p>
        <motion.h4 variants={textVariant(0.9)} className="technologies-header">
          Technologies
        </motion.h4>
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          className="technologies-container"
        >
          {technologies.map((technology, index) => {
            const color = technologiesColors[technology];
            return (
              <motion.span
                initial={{
                  opacity: 0,
                  translateX: index % 2 === 0 ? -20 : -40,
                  translateY: -50,
                }}
                animate={{ opacity: 1, translateX: 0, translateY: 0 }}
                transition={{ duration: 0.25, delay: 1.1 + index * 0.2 }}
                style={{
                  borderBottom: `3px solid ${color}`,
                  "&:hover": { color: `${color}` },
                }}
                key={index}
                className="technology"
              >
                {technology}
              </motion.span>
            );
          })}
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="show"
          variants={projectButtonVariant(0.9)}
          viewport={{ once: true }}
          className="link-buttons"
        >
          <a href={github} target="_blank" rel="noreferrer" className="link">
            <button className="btn">Source Code</button>
          </a>
          <a href={liveDemo} target="_blank" rel="noreferrer" className="link">
            <button className="btn">Live Demo</button>
          </a>
        </motion.div>
      </motion.div>
      <motion.a
        initial="hidden"
        whileInView="show"
        variants={gifVariant(0.1)}
        className="visualization"
        href={liveDemo}
        target="_blank"
        rel="noreferrer"
      >
        <figure className="figure">
          <img className="project-image" src={image} alt={projectName} />
        </figure>
      </motion.a>
    </div>
  );
};
