import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Navigation } from "./components/Navigation/Navigation.jsx";
import { Main } from "./components/main-section/Main.jsx";
import { Contact } from "./components/contact/Contact.jsx";
import { AboutPage } from "./components/about/AboutPage.jsx";
import { FeaturedWork } from "./components/featured-work/FeaturedWork.jsx";
import { Projects } from "./components/projects/Projects.jsx";
import { Footer } from "./components/footer/Footer.jsx";

import "./App.scss";

export default function App() {
  return (
    <div className="application">
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route
            path="/"
            index
            element={
              <>
                <Main /> <FeaturedWork />
              </>
            }
          ></Route>
          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/about" element={<AboutPage />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
