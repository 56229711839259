export const IMAGES = {
  VSCode: require("./images/VSCode.png"),
  github: require("./images/github.png"),
  HTML: require("./images/HTML.png"),
  CSS: require("./images/CSS.png"),
  JS: require("./images/JS.png"),
  typescript: require("./images/typescript.png"),
  React: require("./images/React.png"),
  SCSS: require("./images/SCSS.png"),
  TailwindCSS: require("./images/TailwindCSS.png"),
  API: require("./images/API.png"),
  NodeJS: require("./images/NodeJS.png"),
  Firebase: require("./images/Firebase.png"),
  Figma: require("./images/Figma.png"),
};
